import './App.css';
import printsooq from './assets/comingsoon.png';
import { TypeAnimation } from 'react-type-animation';



function App() {
  return (
    <div className="wrapper">
    
       <img className='img-main' src={printsooq} alt='coming-soon' />
       <span className='title'>
        <p className='paragraph'>Building the future of printing in GCC</p>
       <h1 >COMING SOON 
       <TypeAnimation sequence={[
        '',
        1000,
        '.',
        1000,
        '..',
        1000,
        '...',
        1000,
        
       
       ]}
       
       cursor={true}
       repeat={Infinity}
       />
       </h1>
       </span>
       
    </div>
  );
}

export default App;
